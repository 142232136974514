import React from 'react';
import styled from 'styled-components';
import Logo from '../components/Logo';

const StyledFourOhFour = styled.section`
  width: 100vw;
  height: 100vh;
  display: grid;
  place-content: center;

  h2 {
    margin-top: 40px;
  }
  div {
    display: grid;
    justify-content: center;
  }
`;

export default function fourOhFourPage() {
  return (
    <StyledFourOhFour className="aeriz-404">
      <div>
        <Logo />
        <h2>
          Looks like you're lost, head <a href="/">home</a>
        </h2>
      </div>
    </StyledFourOhFour>
  );
}
