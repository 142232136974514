import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
// import styled from 'styled-components';

export default function Logo() {
  return (
    <StaticImage
      className="logo-main"
      src="../assets/images/aeriz_logo_grey.png"
      alt="Aeriz Logo"
      width={164}
      height={45}
      layout="fixed"
      placeholder="none"
    />
  );
}
